/* eslint-disable react/prop-types */

import dynamic from 'next/dynamic';

// DYNAMIC IMPORT
const Header = dynamic(() => import('./Header'), {
  ssr: true
});
const Footer = dynamic(() => import('./Footer'), {
  ssr: true
});
const MuiAlertCustom = dynamic(() => import('@/ui/MuiAlert/MuiAlertCustom'), {
  ssr: true
});

const Wrapper = ({ children, type, footerType = true }) => {
  return (
    <>
      <MuiAlertCustom />
      <Header type={type} />
      {children}
      {footerType ? <Footer /> : null}
    </>
  );
};

export default Wrapper;
