import Head from 'next/head';
import PropTypes from 'prop-types';

const Seo = ({ title, description, image, url }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="icon" href="/favicon.ico" />
      {/* <!-- Facebook Meta Tags --/> */}
      <meta
        property="og:url"
        content={typeof window !== undefined ? url : ''}
      />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      {/* <!-- Twitter Meta Tags --/> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:domain"
        content={typeof window !== undefined ? url : ''}
      />
      <meta
        property="twitter:url"
        content={typeof window !== undefined ? url : ''}
      />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Head>
  );
};
// Proptypes validation
Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string
};

export default Seo;
