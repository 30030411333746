/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import { location_data } from '@/reduxtoolkit/BaseSlice';
import { useDispatch } from 'react-redux';
import useMap from '@/hooks/useMap';
import { useRouter } from 'next/router';
function Custommap({ searchInputRef, ...props }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const { map, setMap, setGoogle, markerPoints, currentPositionData } =
    useMap();
  const { google } = props;

  useEffect(() => {
    setGoogle(google);
  }, [google]);

  const renderAutoComplete = () => {
    if (!google || !map) return;

    const options = {
      types: ['(regions)']
      // componentRestrictions: { country: "us" },
    };
    const autocomplete = new google.maps.places.Autocomplete(
      searchInputRef.current,
      options
    );
    autocomplete.bindTo('bounds', map);

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      if (!place.geometry) return;

      if (place.geometry.viewport) {
        // map.fitBounds(place.geometry.viewport);
      }

      const data = {
        address: place?.formatted_address,
        lat: place.geometry.location?.lat(),
        lng: place.geometry.location?.lng()
      };

      dispatch(location_data(data));
    });
  };

  useEffect(() => {
    if (router?.query?.location) {
      //try/catch to prevent unwanted errors, if some one passes string instead of json encodedURI
      try {
        const location = JSON.parse(router?.query?.location);
        dispatch(location_data(location));
        if (searchInputRef.current) {
          searchInputRef.current.value = location?.address;
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [router?.query?.location]);

  useEffect(() => {
    renderAutoComplete();
  }, [map]);

  return (
    <Map
      {...props}
      centerAroundCurrentLocation={false}
      fullscreenControl={false}
      keyboardShortcuts={false}
      mapTypeControl={false}
      // streetViewControl={false}
      zoomControl={false}
      streetViewControl={false}
      containerStyle={{
        height: '100%',
        position: 'relative',
        width: '100%'
      }}
      style={{ width: '100%', height: '400px' }}
      onReady={(m, map) => {
        setMap(map);
      }}
    >
      {currentPositionData && (
        <Marker position={currentPositionData.position} />
      )}
      {markerPoints.map((point) => (
        <Marker {...point} />
      ))}
    </Map>
  );
}
const MapWrapper = (props) => {
  return <Custommap {...props} />;
};
export const MapWrapperWithApiKey = GoogleApiWrapper({
  apiKey: 'AIzaSyBs64pTD4Fis_baS1Q2Ro2dQS_yAxB_Bkw',
  libraries: ['places']
});
export default MapWrapperWithApiKey(MapWrapper);
