/* eslint-disable react/button-has-type */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
//**  PROJECT IMPORTS   */
import dynamic from 'next/dynamic';
import styles from '../styles/pages/home.module.scss';
import Box from '@mui/material/Box';
import Slider from 'react-slick';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Wrapper from '../Layout/Wrapper';
import Image from 'next/image';
import { cms_end_point, mediaPath } from '@/api/endpoints';
import InnerHTML from 'dangerously-set-html-content';
// import Link from 'next/link';
import Link from 'next/link';
import { fetch_cmsData } from '@/api/Functions/cms.api';
import Seo from '@/components/SEO/Seo';
import { MapWrapperWithApiKey } from './dashboard/searchbase/Custommap';
import { useRouter } from 'next/router';
import HideImageIcon from '@mui/icons-material/HideImage';
const Grid = dynamic(() => import('@mui/material/Grid'));
//**  DYNAMIC IMPORTS   */

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4.9,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 3000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export async function getServerSideProps() {
  const res = await fetch_cmsData({
    endPoint: cms_end_point.cms,
    data: { slug: 'our_features' },
    method: 'post'
  });
  const sliderdata = await fetch_cmsData({
    endPoint: cms_end_point.homeslider,

    method: 'get'
  });
  const home_data = await fetch_cmsData({
    endPoint: cms_end_point.homecontentdata,

    method: 'get'
  });

  const faqs = await fetch_cmsData({
    endPoint: cms_end_point.cms,
    data: { slug: 'faq' },
    method: 'post'
  });

  const faqList = await fetch_cmsData({
    endPoint: cms_end_point.faq,
    method: 'get'
  });
  return {
    props: {
      homeCMSData: res?.data?.data,
      faqCMSData: faqs?.data?.data,
      faqsListCMSData: faqList?.data?.data,
      slider_data: sliderdata?.data?.data,
      home_contentdata: home_data?.data?.data
    }
  };
}

export default function Home({
  homeCMSData,
  faqsListCMSData,
  faqCMSData,
  slider_data,
  home_contentdata
}) {
  const [expanded, setExpanded] = React.useState(0);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [memberdrop, setMemberdrop] = useState(false);
  const [dropdownData, setDropdownData] = useState('Member');
  // api call
  console.log(slider_data?.getData, 'sdad');
  //fetch_cmsData({ slug: 'abuse-policy' })

  return (
    <Wrapper>
      <Seo
        title="Military Moves"
        description="Military Moves"
        image="/favicon.ico"
        // url={window?.location?.href}
      />
      <div className={styles.relative}>
        <div className={styles.homeBannermain}>
          <div className="container">
            <div className={styles.homeBanner}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                  <Grid md={6} sm={6} xs={12}>
                    <div className={styles.banner_caption}>
                      <div className={styles.bannerContent}>
                        <div className={styles.s_table}>
                          <div className={styles.s_1}>
                            <span className={styles.ban_text}>I'm a </span>{' '}
                            <span className={styles.ban_text1}>
                              {' '}
                              {dropdownData}{' '}
                            </span>{' '}
                            <button
                              type="button"
                              className={styles.select_drop}
                              onClick={() => setMemberdrop(!memberdrop)}
                            >
                              <Image
                                src="/assets/images/selectdropArrow.svg"
                                alt="img"
                                width="10px"
                                height="10px"
                              />
                            </button>
                            {memberdrop ? (
                              <div className={styles.drop_down}>
                                <ul>
                                  <li
                                    onClick={() => {
                                      setDropdownData('Member'),
                                        setMemberdrop(false);
                                    }}
                                  >
                                    Member
                                  </li>
                                  <li
                                    onClick={() => {
                                      setDropdownData('New User'),
                                        setMemberdrop(false);
                                    }}
                                  >
                                    New User
                                  </li>
                                </ul>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className={styles.s_3}>Wanting to</div>
                        <div className={styles.s_3}>
                          {dropdownData === 'Member' ? (
                            <>
                              <span className={styles.ban_text2}>Log In</span>{' '}
                              to My Account
                            </>
                          ) : (
                            <>
                              <span className={styles.ban_text3}>Create</span>{' '}
                              My Account
                            </>
                          )}
                        </div>
                      </div>

                      {dropdownData === 'Member' ? (
                        <Link href="/login">
                          <button
                            type="button"
                            className={`${styles.btn} ${styles.goBtn}`}
                          >
                            Go
                          </button>
                        </Link>
                      ) : (
                        <Link href="/signup">
                          <button
                            type="button"
                            className={`${styles.btn} ${styles.goBtn}`}
                          >
                            Go
                          </button>
                        </Link>
                      )}

                      {/* <button
                      type="button"
                      className={`${styles.btn} ${styles.goBtn}`}
                      >
                      Go
                    </button> */}
                      <div className={styles.banner_search_area}>
                        <div className={styles.search_upper}>
                          {/* <h3>Military Moves</h3>
                        <p>| Moving You Home</p> */}
                          {/* <h4>{home_contentdata?.title}</h4> */}
                        </div>
                        {/* <p className={styles.text_con}>
                        Fort Jackson, adjacent to Columbia, S.C. is a 53,000
                        acre U.S. Army Training Center.
                      </p> */}
                        {/* <PlacesSearch /> */}
                      </div>
                    </div>
                  </Grid>
                  <Grid md={6} sm={6} xs={12} className={styles.banImg__right}>
                    <figure>
                      <img
                        src={`${mediaPath}/homecontent/${home_contentdata?.image}`}
                      />
                    </figure>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </div>
        </div>
        <div className={`${styles.our_features} ${styles.afterBg}`}>
          <div className="container">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={0}>
                <Grid md={6} sm={6} xs={12}>
                  <div className={styles.headings}>
                    <h2>
                      <span>{homeCMSData?.title}</span>
                    </h2>
                    <Link href="/about">
                      <button
                        type="button"
                        className={`${styles.btn} ${styles.learnBtn} ${styles.learn_block}`}
                      >
                        Learn More
                      </button>
                    </Link>
                  </div>
                </Grid>
                <Grid md={6} sm={6} xs={12}>
                  <div className={styles.rightText}>
                    {/* <InnerHTML html={homeCMSData?.content} /> */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: homeCMSData?.content
                      }}
                    ></div>
                    <Link href="/about">
                      <button
                        type="button"
                        className={`${styles.btn} ${styles.learnBtn} ${styles.learn_none}`}
                      >
                        Learn More
                      </button>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
          <div className={styles.sliders_area}>
            <Slider {...settings}>
              {slider_data?.getData?.map((item) => {
                return (
                  <div className={styles.sliderItems}>
                    <figure>
                      {item?.top_image === '' ? (
                        // <img
                        //   style={{
                        //     height: '77px',
                        //     width: '88px',
                        //     marginRight: '10px'
                        //   }}
                        //   src="https://kctbs.ac.in/wp-content/uploads/2014/11/default-placeholder.png"
                        // />
                        <HideImageIcon
                          style={{
                            height: '77px',
                            width: '88px',
                            marginRight: '10px'
                          }}
                        />
                      ) : (
                        <img
                          style={{ width: '65%', height: '85%' }}
                          src={`${mediaPath}/homeSlide/${item?.top_image}`}
                        />
                      )}
                    </figure>
                    <h3>{item?.title}</h3>
                    <p>{item?.description}</p>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
        <div className={`${styles.about_military} ${styles.afterBg}`}>
          <div className={styles.container_about}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} className={styles.itemsCenter}>
                <Grid md={6} sm={6} xs={12} className={styles.military_figure}>
                  <div className={styles.military_left_img}>
                    <img
                      src={`${mediaPath}/homecontent/${home_contentdata?.about_image}`}
                    />
                  </div>
                </Grid>
                <Grid md={6} sm={6} xs={12} className={styles.moves_right}>
                  <div className={styles.headings}>
                    <h2>{home_contentdata?.about_title}</h2>
                    <p>{home_contentdata?.about_description}</p>
                    <Link href="/about">
                      <button
                        type="button"
                        className={`${styles.btn} ${styles.learnBtn}`}
                      >
                        Learn More
                      </button>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
        <div className={`${styles.home_faqs} ${styles.afterBg}`}>
          <div className="container">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={0} className={styles.itemsCenter}>
                <Grid md={6} sm={6} xs={12}>
                  <div className={styles.headings}>
                    <h2>{faqCMSData.title}</h2>
                    {/* <InnerHTML html={faqCMSData.content} /> */}
                    <div
                      className={styles.htmltext2}
                      dangerouslySetInnerHTML={{
                        __html: faqCMSData.content
                      }}
                    ></div>
                    <Link href="/faqs">
                      <button
                        type="button"
                        className={`${styles.btn} ${styles.learnBtn}`}
                      >
                        Learn More
                      </button>
                    </Link>
                  </div>
                </Grid>
                <Grid md={6} sm={6} xs={12}>
                  {faqsListCMSData.map((items, index) => (
                    <div className={styles.faq_accrodian}>
                      <Accordion
                        expanded={expanded === index}
                        onChange={handleChange(index)}
                      >
                        <AccordionSummary
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography>{items.question}</Typography>
                          <button type="button" className={styles.expand_btn}>
                            {expanded === 'panel1' ? (
                              <Image
                                src="/assets/images/minus.svg"
                                alt="img"
                                width="29px"
                                height="29px"
                              />
                            ) : (
                              <Image
                                src="/assets/images/addfaqs.svg"
                                alt="img"
                                width="29px"
                                height="29px"
                              />
                            )}
                          </button>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>{items.answer}</Typography>
                        </AccordionDetails>
                      </Accordion>

                      {/* <Accordion
                      expanded={expanded === 'panel2'}
                      onChange={handleChange('panel2')}
                     >
                      <AccordionSummary
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                       >
                        <Typography>
                          Nunc urna urna, interdum a dignissim id?
                        </Typography>
                        <button type="button" className={styles.expand_btn}>
                          {expanded === 'panel2' ? (
                            <Image
                              src="/assets/images/minus.svg"
                              alt="img"
                              width="29px"
                              height="29px"
                            />
                          ) : (
                            <Image
                              src="/assets/images/addfaqs.svg"
                              alt="img"
                              width="29px"
                              height="29px"
                            />
                          )}
                        </button>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Donec placerat, lectus sed mattis semper, neque lectus
                          feugiat lectus, varius pulvinar diam eros in elit.
                          Pellentesque convallis laoreet laoreet.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === 'panel3'}
                      onChange={handleChange('panel3')}
                    >
                      <AccordionSummary
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                      >
                        <Typography>
                          Phasellus erat eros, scelerisque et accumsan et?
                        </Typography>
                        <button type="button" className={styles.expand_btn}>
                          {expanded === 'panel3' ? (
                            <Image
                              src="/assets/images/minus.svg"
                              alt="img"
                              width="29px"
                              height="29px"
                            />
                          ) : (
                            <Image
                              src="/assets/images/addfaqs.svg"
                              alt="img"
                              width="29px"
                              height="29px"
                            />
                          )}
                        </button>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Fusce consequat iaculis nulla. Vivamus mattis,
                          velit quis malesuada blandit, mi mi hendrerit.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === 'panel4'}
                      onChange={handleChange('panel4')}
                    >
                      <AccordionSummary
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                      >
                        <Typography>
                          Nunc urna urna, interdum a dignissim id?
                        </Typography>
                        <button type="button" className={styles.expand_btn}>
                          {expanded === 'panel4' ? (
                            <Image
                              src="/assets/images/minus.svg"
                              alt="img"
                              width="29px"
                              height="29px"
                            />
                          ) : (
                            <Image
                              src="/assets/images/addfaqs.svg"
                              alt="img"
                              width="29px"
                              height="29px"
                            />
                          )}
                        </button>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Fusce consequat iaculis nulla. Vivamus mattis,
                          velit quis malesuada blandit, mi mi hendrerit.
                        </Typography>
                      </AccordionDetails>
                      </Accordion> */}
                    </div>
                  ))}
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

function PlacesSearchRender({ google }) {
  const router = useRouter();
  const searchInputRef = useRef(null);
  const initiateAutoComplete = useCallback(() => {
    if (!google) {
      return null;
    }
    const options = {
      types: ['(regions)']
      // componentRestrictions: { country: "us" },
    };
    const autocomplete = new google.maps.places.Autocomplete(
      searchInputRef.current,
      options
    );

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      if (!place.geometry) return;
      if (place.geometry.viewport) {
        // map.fitBounds(place.geometry.viewport);
      }

      const data = {
        address: place?.formatted_address,
        lat: place.geometry.location?.lat(),
        lng: place.geometry.location?.lng()
      };
      router.push(
        `/search/map?location=${encodeURIComponent(JSON.stringify(data))}`
      );
    });
  }, [google]);

  useEffect(() => {
    initiateAutoComplete();
  }, [google]);

  return (
    <div className={styles.searchBox}>
      <input
        type="text"
        placeholder="Search by Location"
        ref={searchInputRef}
      />
      <button>
        <img src="/assets/images/icon-search.svg" alt="" />
      </button>
    </div>
  );
}

const PlacesSearch = MapWrapperWithApiKey(PlacesSearchRender);
