import React, { createContext } from "react";
import useMapProvider from "../hooks/useMapProvider";
export const MapContext = createContext(null);
export default function MapProvider({ children }) {
    const  mapProvider = useMapProvider();
  return (
    <MapContext.Provider value={mapProvider}>{children}</MapContext.Provider>
  );
}
export const MapConsumer = MapContext.Consumer;
