import { useCallback, useEffect, useRef, useState } from 'react';

export default function useMapProvider() {
  const [map, setMap] = useState(null);
  const [google, setGoogle] = useState(null);
  const [markerPoints, setMarkerPoints] = useState([]);
  const [currentPositionData, setCurrentPoistionData] = useState({});
  const currentPositionRef = useRef(null);
  const directionsRendererRef = useRef(null);
  const directionsServiceRef = useRef(null);

  const createMarker = useCallback(
    (item) => {
      // requiredtype ={
      //     position: { lat: 0, lng: 0 },
      //     title:"",
      // }
      setMarkerPoints([item]);
    },
    [google, map]
  );


  const getCurrentLocation = (callback) => {
    if (!navigator.geolocation) {
      console.warn("Browser doesn't support geolocation!");
    } else {
      navigator.geolocation.getCurrentPosition(
        (cord) => {
          const position = {
            lat: cord.coords.latitude,
            lng: cord.coords.longitude
          };
          if (position?.lat && position?.lng) {
            const geocoder = new google.maps.Geocoder();

            geocoder
              .geocode({
                location: position
              })
              .then((response) => {
                if (response.results[0]) {
                  const place = response.results[0]; //for future to get city title ...etc
                  const data = {
                    position,
                    place
                  };
                  setCurrentPoistionData(data);
                  currentPositionRef.current = data;
                  if (callback) {
                    callback(data);
                  }
                }
              });
          }
        },
        () => {}
      );
    }
  };

  const moveToCurrentLocation = () => {
    getCurrentLocation((data) => {
      if (map) {
        map.setCenter(data.position);
      }
    });
  };



  useEffect(() => {
    if (map && google) {
      moveToCurrentLocation();

    directionsServiceRef.current = new google.maps.DirectionsService();
      directionsRendererRef.current = new google.maps.DirectionsRenderer();
      directionsRendererRef.current.setMap(map);
    }
  }, [map, google]);

  return {
    map,
    setMap,
    google,
    setGoogle,
    createMarker,
    markerPoints,
    getCurrentLocation,
    currentPositionData,
    moveToCurrentLocation,currentPositionRef,directionsRendererRef,directionsServiceRef
  };
}
